<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on }">
      <v-btn
        block
        v-on="on"
        @click="checkStudio"
        elevation="0"
        :disabled="!$store.getters['auth/getPlan']('mystudio')"
        >{{
          $store.getters["auth/isTattooerStudio"]
            ? $t("edit", { name: "" })
            : $t("add", { name: "" })
        }}</v-btn
      >
    </template>
    <v-card style="overflow: hidden">
      <v-card-title
        v-text="$t('add', { name: $tc('tattooers.studio') })"
        style="text-decoration: underline; text-transform: uppercase"
      />
      <form
        action
        data-vv-scope="studio-form"
        @submit.prevent="validateForm('studio-form')"
      >
        <v-card-text>
          <v-row class="">
            <v-col cols="12" md="5" class="pt-2"
              ><label style="font-size: 12px">{{ $t("studios.name") }}</label>

              <v-text-field
                :disabled="edit == false"
                outlined
                dense
                class="text-field rounded-pill"
                hide-details
                v-model="studio.studio_name"
                style="
                  display: flex;
                  width: 100%;
                  justify-content: left;
                  align-items: left;
                "
              >
              </v-text-field
            ></v-col>
            <v-col cols="12" md="7" class="pt-2" style="display: relative"
              ><label style="font-size: 12px">{{
                $t("studios.address")
              }}</label>

              <v-text-field
                :disabled="edit == false"
                outlined
                dense
                hide-details
                v-model="studio.address"
                class="text-field rounded-pill"
                style="
                  display: flex;
                  justify-content: left;
                  align-items: left;
                  width: 100%;
                "
              >
              </v-text-field
            ></v-col>
          </v-row>
          <v-row v-if="!$vuetify.breakpoint.mdAndDown" class="">
            <v-col cols="2" class="py-0"
              ><label style="font-size: 12px">{{ $t("studios.number") }}</label>

              <v-text-field
                :disabled="edit == false"
                outlined
                v-mask="'#####'"
                v-model="studio.number"
                dense
                type="number"
                v-on:keypress="isNumber($event)"
                class="text-field rounded-pill"
              >
              </v-text-field
            ></v-col>
            <v-col cols="2" class="py-0"
              ><label style="font-size: 12px">{{ $t("studios.floor") }}</label>

              <v-text-field
                :disabled="edit == false"
                outlined
                v-mask="'XXXXX'"
                v-model="studio.piso"
                dense
                class="text-field rounded-pill"
              >
              </v-text-field></v-col
            ><v-col cols="2" class="py-0"
              ><label style="font-size: 12px">{{ $t("studios.door") }}</label>

              <v-text-field
                :disabled="edit == false"
                outlined
                v-mask="'XXXXX'"
                v-model="studio.puerta"
                dense
                class="text-field rounded-pill"
              >
              </v-text-field></v-col
            ><v-col cols="3" class="py-0"
              ><label style="font-size: 12px">{{
                $t("studios.postal_code")
              }}</label>

              <v-text-field
                :disabled="edit == false"
                outlined
                v-mask="'#####'"
                dense
                v-model="studio.postal_code"
                class="text-field rounded-pill"
              >
              </v-text-field></v-col
            ><v-col cols="3" class="py-0"
              ><label style="font-size: 12px">{{ $t("studios.city") }}</label>

              <v-text-field
                :disabled="edit == false"
                outlined
                dense
                class="text-field rounded-pill"
                v-model="studio.city"
              >
              </v-text-field></v-col
          ></v-row>
          <v-row v-if="$vuetify.breakpoint.mdAndDown" xs="12">
            <v-col cols="4">
              <label style="font-size: 12px">{{ $t("studios.number") }}</label>

              <v-text-field
                :disabled="edit == false"
                outlined
                type="number"
                v-on:keypress="isNumber($event)"
                dense
                class="text-field"
                v-mask="'#####'"
                v-model="studio.number"
              >
              </v-text-field
            ></v-col>
            <v-col cols="4"
              ><label style="font-size: 12px">{{ $t("studios.floor") }}</label>

              <v-text-field
                :disabled="edit == false"
                outlined
                dense
                v-mask="'XXXXX'"
                class="text-field"
                v-model="studio.piso"
              >
              </v-text-field></v-col
            ><v-col cols=""
              ><label style="font-size: 12px">{{ $t("studios.door") }}</label>

              <v-text-field
                :disabled="edit == false"
                outlined
                dense
                v-mask="'XXXXX'"
                class="text-field"
                v-model="studio.puerta"
              >
              </v-text-field></v-col
          ></v-row>
          <v-row v-if="$vuetify.breakpoint.mdAndDown">
            <v-col cols="5" v-if="$vuetify.breakpoint.mdAndDown"
              ><label style="font-size: 12px"> C.P. </label>

              <v-text-field
                :disabled="edit == false"
                outlined
                dense
                v-mask="'#####'"
                class="text-field"
                v-model="studio.postal_code"
              >
              </v-text-field></v-col
            ><v-col cols="5" v-if="$vuetify.breakpoint.mdAndDown"
              ><label style="font-size: 12px">{{ $t("studios.city") }}</label>

              <v-text-field
                :disabled="edit == false"
                outlined
                dense
                class="text-field"
                v-model="studio.city"
              >
              </v-text-field
            ></v-col>
          </v-row>
        </v-card-text>
        <timetable ref="timetable" :timetable="studio.timetable"></timetable>

        <v-card-actions>
          <v-spacer />
          <v-btn
            outlined
            style="height: 30px; width: 100px"
            @click="dialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            type="submit"
            elevation="0"
            style="height: 30px; width: 100px !important"
            ><v-icon small style="margin-right: 5px">$save</v-icon>
            {{ $t("save", { name: "" }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  name: "AddStudio",
  directives: {
    mask,
  },
  data() {
    return {
      dialog: false,
      studio: {
        timetable: [[], [], [], [], [], [], []],
      },
    };
  },
  components: {
    CountrySelect: () => import("@/components/ui/CountrySelect"),
    timetable: () => import("@/components/ui/Timetable"),
  },
  computed: {
    URL() {
      return window.location.origin;
    },
  },
  methods: {
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    checkStudio() {
      if (this.$store.getters["auth/isTattooerStudio"]) {
        this.studio = this.$store.state.auth.user.studio;
      }
    },
    ...mapActions("studios", ["addStudioToTattooer"]),
    async validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;

      let form_val = await this.$validator.validateAll(scope);
      if (form_val) {
        this.addStudioToTattooer({ studio: this.studio }).then((data) => {
          this.studio = {};
          this.dialog = false;
        });
      }
    },
  },
};
</script>

<style scoped lang="sass">
label
  display: block
  height: 25px
  color: var(--v-primary-base) !important
</style>